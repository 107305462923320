import 'owl.carousel2'


jQuery(($) => {

    const CAROUSEL_ARGS = {
        Images: {
          margin: 10,
          nav: false,
          dots: true,
          smartSpeed: 1000,
          autoplay: true,
          autoplayTimeout: 4000,
          autoplayHoverPause: true,
          items: 1,
          center: true,
          loop: false,
          navText: [
           '<span class="sr-only">Next Item</span>',
           '<span class="sr-only">Previous Item</span>'
          ]
        }
    }
    
    function disableStartEndNavigation(event) {
      let $carousel           = $(event.target)
      let itemIndex           = event.item.index;
      let $itemsCount         = $carousel.find('.owl-item').length;
      let $itemsActiveCount   = $carousel.find('.owl-item.active').length;
      let $carouselNext       = $(`.carousel__nav-container[data-parent="#${$carousel.attr('id')}"] .owl-next`);
      let $carouselPrev       = $(`.carousel__nav-container[data-parent="#${$carousel.attr('id')}"] .owl-prev`);
      
      if (itemIndex < 1) {
          //console.log('awesome start!');
          $carouselPrev.addClass('disabled')
      } else if (itemIndex >= 1) {
          $carouselPrev.removeClass('disabled')
      }
  
      if (itemIndex == ($itemsCount - $itemsActiveCount)) {
          //console.log('awesome end!');
          $carouselNext.addClass('disabled')
      } else {
          $carouselNext.removeClass('disabled')
      }
    }
    
    function getCarouselArgs(element) {
      if (element.hasClass('carousel--images')) {
        return CAROUSEL_ARGS.Images
      }
    }
  
    $('.owl-carousel').each(function () {
      let $carousel = $(this)
      
      
      
      
      let args = getCarouselArgs($carousel)
      let navContainer = `.carousel__nav-container[data-parent="#${$carousel.attr(
        'id'
      )}"]`
      args.dotsContainer = `${navContainer} .dots`
      if ($carousel.data('media-down')) {
        $(window).on('load resize', () => {
          let breakpoint = $carousel.data('media-down')
          if (window.innerWidth <= getBreakpoint(breakpoint)) {
            $carousel.owlCarousel(args)
          } else {
            $carousel.trigger('destroy.owl.carousel')
          }
        })
      } else {
        $carousel.owlCarousel(args)
      }
      $(navContainer).on('click', 'button', (event) => {
        let action = event.currentTarget.classList.contains('owl-next')
          ? 'next'
          : 'prev'
        $carousel.trigger(`${action}.owl.carousel`)
      })
    })
  })