import "jquery-match-height";
import "bootstrap/js/src/index";
import "./_carousels";

jQuery(document).ready(function($) {
    
    // let menuToggle      = $(".navbar-toggler");
    // let body            = $("body");
    // let subMenuItem     = $(".navbar-nav .menu-item a");


    // menuToggle.on('click', function(){
    //     if(body.hasClass('navOpen')){
    //         body.removeClass('navOpen');
    //     }else{
    //         body.addClass('navOpen');
    //     }
    // });

    // subMenuItem.on('click', function(){
    //     if(body.hasClass('.navOpen')){
    //         body.removeClass('.navOpen');
    //     }else{
    //         body.addClass('.navOpen');
    //     }
    // });

    

    function drawerNav() {
        let body            = $("body");
        let $drawToggle = $('button.navbar-toggler')
        let subMenu         = $(".navbar-nav .menu-item .dropdown-menu");
        let subMenuItem     = $(".navbar-nav .menu-item a");
        let breakpointSm    = 960;



        if ($(window).width() < breakpointSm ) {
            
            subMenu.css('height', 0 );
            $(".dropdown-menu").on('click', () => {
                $(".dropdown-menu").toggleClass('show');
            })
            subMenuItem.click(function(){
                
                var dropdownMenu        = $(this).parent().find("ul");
                var dropdownMenuItems   = dropdownMenu.children('.menu-item');
                var dropdownMenuHeight  = 0;

                subMenu.css('height', 0 );

                dropdownMenuItems.each(function(){
                    dropdownMenuHeight += parseInt($(this).innerHeight(), 10);
                });
                
                dropdownMenu.css('height', dropdownMenuHeight );
            });
        }else{
            subMenu.css('height', 'auto' );
        }   

        
    }


    //Fires on page load
    drawerNav();

    //Recheck when window gets resized.
    $(window).bind('resize', function() {
        drawerNav();
    });

})