import {EventBuilder} from "./_jquery.ga-lib"

// requires: _jquery.ga-lib.js
//Add your Google Analytics Events below
//ga('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject]);

//CATEGORY: Contact

jQuery(document).ready( ($) => {


    const debug = false;
    let eventBuilder = new EventBuilder(debug);
    
    /* # Forms 
    ================================================== */
    eventBuilder.bind('form');

    /* # General Contact 
    ================================================== */
    eventBuilder.bind('a[href^="tel:"], a[href^="mailto:"]');
    
    /* # Address Contact 
    ================================================== */
    eventBuilder.bind('.ga-address');

    
    
    /* # Outside Links 
    ================================================== */
    let $outsideLinks = $('.social-links a').filter(function() {
        	return this.hostname && this.hostname !== location.hostname;
    })
    eventBuilder.bind($outsideLinks, { 
        action: "Click",
        category: "Offsite",
        beforeLabel: "Social Media Offsite Click | ",
        label: function(element) { 
            return element.attr("href");
        }
    }) 
	
} );	